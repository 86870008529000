const _ = require('lodash');

function campaignTitle(campaign, page = campaign.defaultPage) {
    return _.get(
        campaign,
        `landing.locales.${page}.title`,
        _.get(
            campaign,
            `landing.locales.${page}.name`,
            _.get(campaign, `locales.${page}.pageName`, ''),
        ),
    );
}

function campaignOrganization(campaign, page = campaign.defaultPage) {
    return _.get(
        campaign,
        `locales.${page}.name`,
        campaignTitle(campaign, page),
    );
}

module.exports = {
    campaignTitle,
    campaignOrganization,
};
