const { get } = require('lodash');
const { PAYMENT_METHODS_INFO } = require('../constants');

const CONFIGURABLE_PAYMENT_METHODS = Object.entries(
    PAYMENT_METHODS_INFO,
).reduce((methods, [method, info]) => {
    if (info.configProp) {
        methods.push(method);
    }
    return methods;
}, []);

const getPaymentMethods = () => {
    return Object.keys(PAYMENT_METHODS_INFO).reduce(
        (payCollection, paymentMethod) => {
            payCollection.push({
                label: PAYMENT_METHODS_INFO[paymentMethod].label,
                value: paymentMethod,
            });
            return payCollection;
        },
        [],
    );
};

const paymentMethodType = paymentMethod =>
    get(PAYMENT_METHODS_INFO, `${paymentMethod}.type`);

const paymentMethodLabel = paymentMethod =>
    get(PAYMENT_METHODS_INFO, `${paymentMethod}.label`);

const paymentMethodLabelProp = paymentMethod =>
    get(PAYMENT_METHODS_INFO, `${paymentMethod}.labelProp`);

const paymentMethodTipping = paymentMethod =>
    get(PAYMENT_METHODS_INFO, `${paymentMethod}.tipping`) || false;

const paymentMethodConfigProp = paymentMethod =>
    get(PAYMENT_METHODS_INFO, `${paymentMethod}.configProp`, null);

const paymentMethodSupportsGiftAid = paymentMethod =>
    get(PAYMENT_METHODS_INFO, `${paymentMethod}.giftAid`, false);

module.exports = {
    CONFIGURABLE_PAYMENT_METHODS,
    getPaymentMethods,
    paymentMethodLabel,
    paymentMethodLabelProp,
    paymentMethodType,
    paymentMethodTipping,
    paymentMethodConfigProp,
    paymentMethodSupportsGiftAid,
};
