import Repository from './Repository';
import parseErrorMessage from '../../helpers/parseErrorMessage';

class DonationMessageRepository extends Repository {
    async sendBatchMessage(messageProps) {
        try {
            return await this.axios.post(`/batch`, messageProps);
        } catch (error) {
            const message = parseErrorMessage(error);
            throw new Error(message);
        }
    }

    async getMessageCost(campaignId) {
        try {
            return await this.axios.get(`/cost/${campaignId}`);
        } catch (error) {
            const message = parseErrorMessage(error);
            throw new Error(message);
        }
    }
}

export default new DonationMessageRepository({
    baseUrl: '/donation/messaging',
});
