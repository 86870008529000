const get = require('lodash/get');
const { GATEWAYS, PAYMENT_GATEWAYS } = require('../constants');

const OFFLINE_GATEWAYS = ['offline', 'api'];

module.exports = function (currency, { filterGateways = null } = {}) {
    const gatewayKeys = Object.keys(GATEWAYS).filter(key => {
        if (
            !OFFLINE_GATEWAYS.includes(key) &&
            (!filterGateways || filterGateways.includes(key))
        ) {
            return Object.values(GATEWAYS[key].support).some(
                support =>
                    support.all || support.currencies?.indexOf(currency) > -1,
            );
        }
    });
    return gatewayKeys.reduce((curCollection, curKey) => {
        curCollection.push({
            label: GATEWAYS[curKey].label,
            value: curKey,
        });
        return curCollection;
    }, []);
};

module.exports.getAllGateways = function () {
    return Object.keys(GATEWAYS);
};

module.exports.gatewayFlow = function (gateway, paymentMethod = null) {
    return paymentMethod &&
        get(GATEWAYS, `${gateway}.paymentMethods`, []).includes(paymentMethod)
        ? get(GATEWAYS, `${gateway}.flow.${paymentMethod}`, 'charge')
        : get(GATEWAYS, `${gateway}.flow`, 'charge');
};

module.exports.gatewaySupportsPaymentMode = function (gateway, paymentMode) {
    return get(GATEWAYS, `${gateway}.${paymentMode}`, false);
};

module.exports.gatewaySupportsTipping = function (gateway) {
    return get(GATEWAYS, `${gateway}.tipping`, true);
};

module.exports.enableGatewayReceiptName = function (gateway) {
    return get(GATEWAYS, `${gateway}.receiptName`, false);
};

module.exports.gatewayDefaultPaymentMethod = function (gateway) {
    return get(GATEWAYS, `${gateway}.defaultPaymentMethod`, 'card');
};

module.exports.gatewaySupportsPaymentMethodAndCurrency = function (
    gateway,
    currency,
    paymentMethod,
) {
    const support = get(GATEWAYS, `${gateway}.support.${paymentMethod}`, null);
    if (!support) {
        return false;
    }

    return support.all || support.currencies.indexOf(currency) > -1;
};

module.exports.gatewayInstallmentMaxMonths = function (gateway) {
    return [PAYMENT_GATEWAYS.MESHULAM, PAYMENT_GATEWAYS.TRANZILA].includes(
        gateway,
    )
        ? 12
        : 120;
};

module.exports.isVoucherGateway = function (gateway) {
    return GATEWAYS[gateway].isVoucher;
};
