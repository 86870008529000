import {
    createAsyncThunk,
    createSelector,
    createSlice,
} from '@reduxjs/toolkit';
import get from 'lodash/get';
import has from 'lodash/has';
import api from 'client/services/api';
import { campaignOrganization } from 'common/helpers/campaign';
import { getDefaultPaymentMethod } from 'common/helpers/payments';
import caseInsensitiveEqual from 'common/helpers/caseInsensitiveEqual';
import findActiveRound from 'common/helpers/findActiveRound';
import isToggled from 'common/helpers/isToggled';
import * as transformTrackingId from 'common/helpers/transformTrackingIds';
import {
    CAMPAIGN_CONFETTI_TRIGGER_TYPES,
    CAMPAIGN_DEFAULT_DONATION_TEXT,
} from 'common/constants';
import isDateStringPast from 'common/helpers/isDatePast';

export const fetchBySlug = createAsyncThunk('campaign/fetchBySlug', slug =>
    api.campaign.fetchBySlug(slug),
);

function getCampaignRaisedAmount(campaign, page) {
    if (!campaign) {
        return 0;
    }

    const pageName = page || campaign.defaultPage;
    const currency = get(campaign, `locales.${pageName}.goalCurrency`);

    const amount = get(
        campaign,
        `totalProgress.amounts.${currency}`,
        get(campaign, 'donationsSum'),
    );

    return Number(amount || 0);
}

function isBonusRoundEnabled(round, ratedAmount, goal) {
    return (
        round.enabled === true && // enabled
        (round.activationType == 2 || // activate immediately trigger type
            ratedAmount >= goal) // on goal reach
    );
}

const campaignSlice = createSlice({
    name: 'campaign',
    initialState: {
        selectedCampaign: null,
        selectedLayerItem: window.selectedLayerItem,
        isCampaignExpired: false,
        loading: 'idle',
        error: null,
        donorPflSourceId: null,
    },
    reducers: {
        setLayerItem(state, { payload }) {
            state.selectedLayerItem = payload;
        },
        updateDonationsSum(state, { payload }) {
            const { campaign } = payload;
            state.selectedCampaign.donationsSum = campaign.donationsSum;
            state.selectedCampaign.donationsCount = campaign.donationsCount;
            state.selectedCampaign.totalProgress = campaign.totalProgress;
        },
        updateGiving(state, { payload }) {
            const { donor } = payload;
            const idx = state.selectedCampaign.givings.findIndex(
                giving => giving.id === donor.giving.id,
            );
            state.selectedCampaign.givings[idx] = donor.giving;
        },
        updateSelectedLayerItemStatistics(state, { payload }) {
            let layerItem;
            if (Array.isArray(payload)) {
                [layerItem] = payload.filter(
                    li => li.id === state.selectedLayerItem?.id,
                );
            } else {
                layerItem = payload;
            }

            if (layerItem) {
                const getLayerItemStat = (name, defaultValue) =>
                    layerItem[name] ||
                    layerItem.statistics?.[name] ||
                    defaultValue;

                state.selectedLayerItem.donorsCount = getLayerItemStat(
                    'donorsCount',
                    0,
                );
                state.selectedLayerItem.donationsAmount = getLayerItemStat(
                    'donationsAmount',
                    0,
                );
                state.selectedLayerItem.progress = getLayerItemStat(
                    'progress',
                    0,
                );
                state.selectedLayerItem.lastDonationAt = getLayerItemStat(
                    'lastDonationAt',
                    null,
                );
                state.selectedLayerItem.statistics = layerItem.statistics;
            }
        },
        updateCampaign(state, { payload }) {
            state.selectedCampaign = payload;
        },

        updateIsCampaignExpired(state, { payload }) {
            state.isCampaignExpired = payload;
        },
        updateDonorPflSourceId(state, { payload }) {
            state.donorPflSourceId = payload;
        },
    },
    extraReducers: builder => {
        builder.addCase(fetchBySlug.pending, state => {
            state.loading = 'pending';
        });
        builder.addCase(fetchBySlug.fulfilled, (state, action) => {
            state.selectedCampaign = action.payload;
            state.isCampaignExpired = isDateStringPast(action.payload.endAt);
            state.loading = 'idle';
            state.error = null;
        });
        builder.addCase(fetchBySlug.rejected, (state, action) => {
            state.loading = 'idle';
            state.error = action.payload;
            console.error(action.payload);
        });
    },
});

export const {
    setLayerItem,
    updateDonationsSum,
    updateGiving,
    updateSelectedLayerItemStatistics,
    updateCampaign,
    updateIsCampaignExpired,
    updateDonorPflSourceId,
} = campaignSlice.actions;

export const selectCampaign = state => state.campaign;
export const selectIsLoading = state => state.campaign.loading === 'pending';
export const selectCurrentLanguage = () => 'en';
export const selectPageNameFromState = state => state.pageName;
export const selectCurrentSlug = ({ pageName }) => {
    if (typeof pageName === 'string') {
        return null;
    }

    const pathnameChunks = get(document, 'location.pathname', '').split('/');
    return pathnameChunks[1] || null;
};
export const selectSelectedCampaign = createSelector(
    selectCampaign,
    campaignPageState =>
        campaignPageState ? campaignPageState.selectedCampaign : null,
);
export const selectSelectedCampaignId = createSelector(
    selectSelectedCampaign,
    campaign => (campaign ? campaign.id : null),
);
export const selectSelectedCampaignAccountId = createSelector(
    selectSelectedCampaign,
    campaign => (campaign ? campaign.accountId : null),
);
export const selectSelectedLayerItem = createSelector(
    selectCampaign,
    campaign => campaign.selectedLayerItem,
);
export const selectEnabledLayerDonation = createSelector(
    selectSelectedLayerItem,
    layerItem => {
        return Boolean(get(layerItem, 'layer.allowDonations'));
    },
);
export const selectDataSource = createSelector(
    selectSelectedCampaign,
    selectSelectedLayerItem,
    (campaign, layerItem) => layerItem || campaign,
);

export const selectPageLanguage = createSelector(
    selectSelectedCampaign,
    selectSelectedLayerItem,
    (campaign, layerItem) => ({
        defaultLanguage: get(
            campaign,
            `locales.${campaign.defaultPage}.language`,
            'en',
        ),
        pageLanguage: layerItem?.language,
    }),
);

export const selectPageName = createSelector(
    selectSelectedCampaign,
    selectCurrentSlug,
    selectPageNameFromState,
    (campaign, currentSlug, pageName) => {
        if (pageName) {
            return pageName;
        }

        const slugs = get(campaign, 'slugs', {});

        for (let [pageName, slug] of Object.entries(slugs)) {
            if (caseInsensitiveEqual(slug, currentSlug)) {
                return pageName;
            }
        }

        return null;
    },
);

export const selectRatedAmount = createSelector(
    selectSelectedCampaign,
    selectPageName,
    (campaign, pageName) => getCampaignRaisedAmount(campaign, pageName),
);

export const selectBaseCurrencyAmount = createSelector(
    selectSelectedCampaign,
    campaign => getCampaignRaisedAmount(campaign),
);

export const selectPayments = createSelector(
    selectSelectedCampaign,
    selectPageName,
    (campaign, pageName) => get(campaign, `payments.${pageName}`, {}),
);

export const selectGoals = createSelector(
    selectSelectedCampaign,
    selectBaseCurrencyAmount,
    (campaign, baseCurrencyAmount) => {
        const baseCurrency = get(
            campaign,
            `locales.${campaign.defaultPage}.goalCurrency`,
        );
        const goal = Number(
            get(campaign, `locales.${campaign.defaultPage}.goal`),
        );
        const [bonusGoal = 0, secondBonusGoal = 0] = get(
            campaign,
            'bonus.rounds',
            [],
        ).reduce((roundAmounts, round) => {
            if (round.enabled && round.goal[baseCurrency]) {
                roundAmounts.push(Number(round.goal[baseCurrency]));
            }

            return roundAmounts;
        }, []);

        return {
            goal,
            bonusGoal,
            secondBonusGoal,
            baseCurrencyAmount,
        };
    },
);

export const selectGoalProgress = createSelector(
    selectGoals,
    ({ goal, bonusGoal, secondBonusGoal, baseCurrencyAmount }) => {
        if (goal === 0) {
            return null;
        }

        const goalProgress = baseCurrencyAmount / goal;
        const goal1Progress = baseCurrencyAmount / (goal + bonusGoal);
        const goal2Progress =
            baseCurrencyAmount / (goal + bonusGoal + secondBonusGoal);

        return {
            goalProgress,
            goal1Progress,
            goal2Progress,
        };
    },
);

export const selectProgressGradation = createSelector(
    selectGoals,
    ({ goal, bonusGoal, secondBonusGoal, baseCurrencyAmount }) => {
        const firstRoundGoal = goal + bonusGoal;
        const totalGoal = firstRoundGoal + secondBonusGoal;
        const totalPercent = parseInt((baseCurrencyAmount * 100) / goal, 10);
        let goal1Percent = 0;
        let goal2Percent = 0;
        let goalPercent;

        if (goal >= baseCurrencyAmount) {
            goalPercent = Math.round((baseCurrencyAmount * 100) / goal);
        } else {
            goalPercent = Math.round((goal * 100) / totalGoal);

            if (firstRoundGoal >= baseCurrencyAmount) {
                goal1Percent = Math.round(
                    (baseCurrencyAmount * 100) / firstRoundGoal,
                );
            } else {
                goal1Percent = Math.round((firstRoundGoal * 100) / totalGoal);
                goal2Percent = Math.round(
                    (baseCurrencyAmount * 100) / totalGoal,
                );
            }
        }

        return {
            goalPercent,
            goal1Percent,
            goal2Percent,
            totalPercent,
        };
    },
);

export const selectPageSpecificCampaignData = createSelector(
    selectSelectedCampaign,
    selectPageName,
    (campaign, pageName) => {
        const defaultData = {
            name: null,
            shortDescription: null,
            image: null,
            video: null,
            logo: null,
            banner: {},
            bodyImage: null,
            goalCurrency: 'USD',
            donationText: CAMPAIGN_DEFAULT_DONATION_TEXT.en,
            palette: {},
            hideHeader: false,
            hideFooter: false,
        };
        // @todo: modify, accordingly selected language.

        const data = get(campaign, `locales.${pageName}`, defaultData);

        return {
            ...data,
            showShortDescription: isToggled(
                data,
                'showShortDescription',
                data.shortDescription,
            ),
            showDonationText: isToggled(
                data,
                'showDonationText',
                data.donationText,
            ),
        };
    },
);

export const selectGivings = createSelector(
    selectSelectedCampaign,
    selectPageName,
    campaign => get(campaign, `givings`, []),
);

export const selectRdpOrder = createSelector(selectSelectedCampaign, campaign =>
    get(campaign, `rdpOrder`, { donorsTab: 0, aboutTab: 999 }),
);

export const selectRdpLabel = createSelector(selectSelectedCampaign, campaign =>
    get(campaign, `rdpLabel`, {}),
);

export const selectRdpHiddenTabs = createSelector(
    selectSelectedCampaign,
    campaign => get(campaign, `rdpHiddenTabs`, []),
);

export const selectLandingData = createSelector(
    selectSelectedCampaign,
    selectPageName,
    selectSelectedLayerItem,
    (campaign, pageName, layerItem) => {
        const landingData = {
            ...get(campaign, `landing.locales.${pageName}`, {}),
        };

        if (layerItem?.creatives?.customizeFirstTagLine) {
            landingData.showFirstTagline = true;
            landingData.firstTagline = layerItem.creatives.firstTagline;
        }

        if (layerItem?.creatives?.customizeSecondTagLine) {
            landingData.showSecondTagline = true;
            landingData.secondTagline = layerItem.creatives.secondTagline;
        }

        if (layerItem?.creatives?.customizeAboutText) {
            landingData.text = layerItem.creatives.aboutText;
        }

        if (landingData.gaTrackingId) {
            landingData.gaTrackingId = transformTrackingId(
                landingData.gaTrackingId,
            );
        }

        if (landingData.fbTrackingId) {
            landingData.fbTrackingId = transformTrackingId(
                landingData.fbTrackingId,
            );
        }

        return landingData;
    },
);

export const selectCheckoutData = createSelector(
    selectSelectedCampaign,
    selectPageName,
    (campaign, pageName) => get(campaign, `checkout.locales.${pageName}`, {}),
);

export const selectCheckoutCustomFields = createSelector(
    selectSelectedCampaign,
    selectPageName,
    (campaign, pageName) => get(campaign, `checkoutFields.${pageName}`, {}),
);

export const selectCampaignSlug = createSelector(
    selectSelectedCampaign,
    selectPageName,
    (campaign, pageName) => get(campaign, `slugs.${pageName}`),
);

export const selectCampaignOrganization = createSelector(
    selectSelectedCampaign,
    selectPageName,
    (campaign, pageName) => campaignOrganization(campaign, pageName),
);

export const selectCampaignPageData = createSelector(
    selectPageSpecificCampaignData,
    selectSelectedLayerItem,
    (campaignData, layerItem) => {
        const data = { ...campaignData };

        if (layerItem) {
            data.name = layerItem.name;
            if (layerItem.logo) data.logo = layerItem.logo;
            data.isLayerItem = true;

            const { creatives } = layerItem;
            if (creatives?.customizeBodyImage) {
                data.bodyImage = creatives.bodyImage;
                data.mobileBodyImage = creatives.mobileBodyImage
                    ? creatives.mobileBodyImage
                    : creatives.bodyImage;
                data.isMobileBodyImageEnabled = isToggled(
                    creatives,
                    'isMobileBodyImageEnabled',
                    creatives.mobileBodyImage,
                );
            }
            if (creatives?.customizeBanner) {
                data.banner = creatives.banner;
            }
        }

        return data;
    },
);

export const selectHeroSlides = createSelector(
    selectPageSpecificCampaignData,
    selectSelectedLayerItem,
    (campaignLocale, layerItem) => {
        let bannerData = campaignLocale.banner;

        if (layerItem?.creatives?.customizeBanner) {
            bannerData = layerItem.creatives.banner;
        }

        const images = get(bannerData, 'images', []).filter(Boolean);
        const mobileImages = get(bannerData, 'mobileImages', []).filter(
            Boolean,
        );

        // to maintain backward compatibillity
        const isMobileImagesEnabled = get(bannerData, 'isMobileImagesEnabled')
            ? bannerData.isMobileImagesEnabled
            : mobileImages.length > 0;
        const isVideoEnabled = has(bannerData, 'isVideoEnabled')
            ? bannerData.isVideoEnabled
            : !!get(bannerData, 'video.url');

        bannerData = {
            ...bannerData,
            images,
            mobileImages,
            interval: bannerData?.interval,
            isMobileImagesEnabled,
            isVideoEnabled,
        };

        return bannerData;
    },
);

export const selectCheckoutHeroSlide = createSelector(
    selectSelectedCampaign,
    selectPageName,
    selectHeroSlides,
    (campaign, pageName, campaignHeroSlides) => {
        const sameAsCampaign = get(
            campaign,
            `checkout.locales.${pageName}.sameAsCampaign`,
            false,
        );
        const imageUrl = get(
            campaign,
            `checkout.locales.${pageName}.image`,
            null,
        );
        const mobileImages = get(
            campaign,
            `checkout.locales.${pageName}.mobileImages`,
            null,
        );

        if (sameAsCampaign) {
            return campaignHeroSlides;
        }

        if (imageUrl || mobileImages) {
            return {
                images: imageUrl ? [imageUrl] : [],
                mobileImages: mobileImages ? mobileImages.filter(Boolean) : [],
            };
        }

        return null;
    },
);

export const selectGoal = createSelector(selectPageSpecificCampaignData, data =>
    Number(get(data, 'goal', 0)),
);

export const selectCurrency = createSelector(
    selectPageSpecificCampaignData,
    data => {
        return get(data, 'goalCurrency', 'USD');
    },
);

export const selectBonusRound = createSelector(
    selectSelectedCampaign,
    selectRatedAmount,
    selectGoal,
    selectCurrency,
    findActiveRound,
);

export const selectNextBonusRoundGoal = createSelector(
    selectSelectedCampaign,
    selectRatedAmount,
    selectGoal,
    selectCurrency,
    selectBonusRound,
    (campaign, ratedAmount, goal, currency, bonusRound) => {
        const nextRound = get(campaign, 'bonus.rounds', []).find(round =>
            isBonusRoundEnabled(round, ratedAmount, goal),
        );

        return nextRound
            ? Number(bonusRound?.goal[currency] || nextRound.goal[currency])
            : 0;
    },
);

export const selectSecondBonusRound = createSelector(
    selectSelectedCampaign,
    campaign => {
        const bonusRound = get(campaign, 'bonus.rounds', []);

        if (bonusRound[1] && bonusRound[1].enabled) {
            return bonusRound[1];
        }

        return null;
    },
);

export const selectBonusGoal = createSelector(
    selectBonusRound,
    selectCurrency,
    (round, currency) => Number(get(round, `goal.${currency}`, 0)),
);

export const selectSecondBonusGoal = createSelector(
    selectSecondBonusRound,
    selectCurrency,
    (round, currency) => Number(get(round, `goal.${currency}`, 0)),
);

export const selectMatchRatio = createSelector(
    selectSelectedCampaign,
    selectBonusRound,
    (campaign, bonusRound) => {
        if (!campaign) {
            return 0; // no data yet in redux state
        }

        if (bonusRound) {
            return Number(get(bonusRound, 'matchRatio', 1));
        }

        const goal = Number(
            get(campaign, `locales.${campaign.defaultPage}.goal`),
        );
        const baseCurrencyAmount = getCampaignRaisedAmount(campaign);
        if (campaign.isResetMatchRatio && baseCurrencyAmount >= goal) {
            return 1;
        }

        return Number(get(campaign, 'matchRatio', 1));
    },
);

export const selectEnableTimer = createSelector(
    selectSelectedCampaign,
    campaign => get(campaign, 'landing.enableTimer', false),
);

export const selectHideProgressBar = createSelector(
    selectSelectedCampaign,
    campaign => get(campaign, 'landing.hideProgressBar', false),
);

export const selectHideGoalAmount = createSelector(
    selectSelectedCampaign,
    campaign => get(campaign, 'landing.hideGoalAmount', false),
);

export const selectHideRaisedAmount = createSelector(
    selectSelectedCampaign,
    campaign => get(campaign, 'landing.hideRaisedAmount', false),
);

export const selectHideTime = createSelector(selectSelectedCampaign, campaign =>
    get(campaign, 'landing.hideTime', false),
);

export const selectHideClock = createSelector(
    selectSelectedCampaign,
    campaign => get(campaign, 'landing.hideClock', false),
);

export const selectHideDonateArea = createSelector(
    selectSelectedCampaign,
    campaign => get(campaign, 'landing.hideDonateArea', false),
);

export const selectHideRdpMenu = createSelector(
    selectSelectedCampaign,
    campaign => get(campaign, 'landing.hideRdpMenu', false),
);

export const selectUseUpdatedPerkDesign = createSelector(
    selectSelectedCampaign,
    campaign => get(campaign, 'landing.useUpdatedPerkDesign', false),
);

export const selectEndCampaignTimestamp = createSelector(
    selectSelectedCampaign,
    campaign => {
        const endAt = get(campaign, 'endAt', null);
        return endAt ? Number(new Date(endAt)) : null;
    },
);

export const selectStartCampaignTimestamp = createSelector(
    selectSelectedCampaign,
    campaign => {
        const startAt = get(campaign, 'startAt', null);
        return startAt ? Number(new Date(startAt)) : null;
    },
);

export const selectCurrenciesWithSettings = createSelector(
    selectPayments,
    selectCurrency,
    (payments, pageCurrency) => {
        const currencies = Object.keys(payments);

        return currencies.reduce((options, currency) => {
            if (/[A-Z]{3}/.test(currency)) {
                options.push({
                    name: currency,
                    value: currency,
                    selected: pageCurrency === currency,
                });
            }
            return options;
        }, []);
    },
);

export const selectCurrencies = createSelector(
    selectPayments,
    selectCurrency,
    (payments, pageCurrency) =>
        payments.currencies?.map(currencyString => ({
            name: currencyString,
            value: currencyString,
            selected: pageCurrency === currencyString,
        })),
);

export const selectDonationText = createSelector(
    selectCampaignPageData,
    ({ donationText }) => donationText,
);

export const selectRedirectAfterDonation = createSelector(
    selectCheckoutData,
    ({
        isRedirectAfterDonationEnabled,
        redirectAfterDonationUrl,
        includeDonationDataInRedirectUrl = false,
    }) => {
        return {
            isRedirectAfterDonationEnabled,
            redirectAfterDonationUrl,
            includeDonationDataInRedirectUrl,
        };
    },
);

export const selectEnableDonations = createSelector(
    selectSelectedCampaign,
    campaignData => {
        return Boolean(get(campaignData, 'isActive', 0));
    },
);

export const selectPaymentMethod = createSelector(
    selectPayments,
    selectCurrency,
    (payments, currency) => getDefaultPaymentMethod(payments, currency),
);

export const selectIsMatchMyGiftEnabled = createSelector(
    selectSelectedCampaign,
    selectPageName,
    (campaign, pageName) =>
        get(campaign, ['locales', pageName, 'enableMatchMyGift'], false),
);

export const selectIsDonorPflEnabled = createSelector(
    selectSelectedCampaign,
    selectPageName,
    (campaign, pageName) =>
        get(campaign, ['locales', pageName, 'isDonorPflEnabled'], false),
);

export const selectEnableConfetti = createSelector(
    selectSelectedCampaign,
    selectGoalProgress,
    selectEndCampaignTimestamp,
    selectCampaign,
    (campaign, progress, endAtTimestamp, { isCampaignExpired }) => {
        const triggerType = get(
            campaign,
            'landing.confettiTriggerType',
            CAMPAIGN_CONFETTI_TRIGGER_TYPES.GOAL_REACHED,
        );
        const isConfettiEnabled = get(
            campaign,
            'landing.enableConfetti',
            false,
        );
        const isBonus1Enabled = get(campaign, 'bonus.rounds.0.enabled', false);
        const isBonus2Enabled = get(campaign, 'bonus.rounds.1.enabled', false);

        if (!isConfettiEnabled) {
            return false;
        }

        switch (triggerType) {
            case CAMPAIGN_CONFETTI_TRIGGER_TYPES.BONUS_2_REACHED:
                return isBonus2Enabled && progress.goal2Progress >= 1;
            case CAMPAIGN_CONFETTI_TRIGGER_TYPES.BONUS_1_REACHED:
                return isBonus1Enabled && progress.goal1Progress >= 1;
            case CAMPAIGN_CONFETTI_TRIGGER_TYPES.GOAL_REACHED:
                return progress.goalProgress >= 1;
            case CAMPAIGN_CONFETTI_TRIGGER_TYPES.CLOCK_RUNS_OUT:
                return isCampaignExpired;
            case CAMPAIGN_CONFETTI_TRIGGER_TYPES.ALWAYS:
                return true;
            default:
                return false;
        }
    },
);

export const selectDonorPflSourceId = createSelector(
    selectCampaign,
    campaignPageState =>
        campaignPageState ? campaignPageState.donorPflSourceId : null,
);

export const selectTotalGoalAmount = createSelector(
    selectSelectedCampaign,
    selectRatedAmount,
    selectGoal,
    selectCurrency,
    (campaign, ratedAmount, goal, currency) => {
        if (goal === 0) {
            return 0;
        }
        return get(campaign, 'bonus.rounds', []).reduce(
            (cumulativeGoal, bonusRound) => {
                return isBonusRoundEnabled(
                    bonusRound,
                    ratedAmount,
                    cumulativeGoal,
                )
                    ? cumulativeGoal + bonusRound?.goal[currency]
                    : cumulativeGoal;
            },
            goal,
        );
    },
);

export default campaignSlice.reducer;
