const { CURRENCIES } = require('../constants');

const DEFAULT_CHECKOUT_CUSTOM_FIELDS = {
    phone: {
        label: 'Phone Number',
        type: 'text',
        isEnabled: true,
        isRequired: true,
        currencies: [...CURRENCIES],
        order: 0,
    },
    email: {
        label: 'E-mail',
        type: 'email',
        isEnabled: true,
        isRequired: true,
        currencies: [...CURRENCIES],
        order: 1,
    },
    address1: {
        label: 'Address Line 1',
        type: 'text',
        isEnabled: true,
        isRequired: true,
        currencies: [...CURRENCIES],
        order: 2,
    },
    address2: {
        label: 'Address Line 2',
        type: 'text',
        isEnabled: false,
        isRequired: false,
        currencies: [...CURRENCIES],
        order: 3,
    },
    city: {
        label: 'City',
        type: 'text',
        isEnabled: false,
        isRequired: false,
        currencies: [...CURRENCIES],
        order: 4,
    },
    state: {
        label: 'State',
        type: 'text',
        isEnabled: false,
        isRequired: false,
        currencies: [...CURRENCIES],
        order: 5,
    },
    country: {
        label: 'Country',
        type: 'text',
        isEnabled: false,
        isRequired: false,
        currencies: [...CURRENCIES],
        order: 6,
    },
    zip: {
        label: 'Zip Code',
        type: 'text',
        isEnabled: false,
        isRequired: false,
        currencies: [...CURRENCIES],
        order: 7,
    },
};

const GATEWAY_SPECIFIC_CHECKOUT_FIELDS = {
    stripe: {
        zip: {
            label: 'Zip Code',
            type: 'text',
            isEnabled: true,
            canOverride: false,
            isRequired: true,
        },
    },
    yaadpay: {},
    myofficeguy: {
        idnumber: {
            label: 'Citizen ID',
            type: 'text',
            isEnabled: true,
            canOverride: true,
            isRequired: false,
        },
    },
    payme: {
        phone: {
            label: 'Phone Number',
            type: 'text',
            isEnabled: true,
            canOverride: false,
            isRequired: true,
        },
    },
    cardknox: {},
    nedarim: {
        citizenId: {
            label: 'Citizen ID',
            type: 'number',
            isEnabled: true,
            canOverride: false,
            isRequired: false,
        },
    },
    nmi: {},
    paypal: {},
    banquest: {},
    payfast: {},
    meshulam: {
        phone: {
            label: 'Phone Number',
            type: 'text',
            isEnabled: true,
            canOverride: false,
            isRequired: true,
        },
    },
    tranzila: {},
    chariot: {},
    donorsfund: {},
};

module.exports = {
    DEFAULT_CHECKOUT_CUSTOM_FIELDS,
    GATEWAY_SPECIFIC_CHECKOUT_FIELDS,
};
