const FINANCIALS_CURRENCY_FISCAL_SPONSOR_TYPES = Object.freeze({
    PEF: 'PEF',
});

const FINANCIALS_CURRENCY_FISCAL_SPONSOR_VALUES = Object.freeze(
    Object.values(FINANCIALS_CURRENCY_FISCAL_SPONSOR_TYPES),
);

/*
    When adding RTL language translations (Hebrew, Arabic...), make sure to wrap in:
    <p dir="rtl"></p>
*/
const FINANCIALS_DEFAULT_TIPPING_TEXTS = Object.freeze({
    en: {
        tippingCheckoutText:
            'Thank you for covering the processing fees to ensure the maximum of your donation goes to the nonprofit.',
        tippingThankYouText: 'Thank you for covering processing fees:',
        tippingPlatformText: 'Processing Fees Covered:',
    },
    he: {
        tippingCheckoutText:
            '<p dir="rtl">תודה על השתתפותך בעלויות הסליקה על מנת שמקסימום מתרומתך יגיע לארגון.</p>',
        tippingThankYouText:
            '<p dir="rtl">תודה על השתתפותך בעלויות הסליקה על סך:</p>',
        tippingPlatformText: 'השתתפות בעלויות סליקה:',
    },
    es: {
        tippingCheckoutText:
            'Thank you for covering the processing fees to ensure the maximum of your donation goes to the nonprofit.',
        tippingThankYouText: 'Thank you for covering processing fees:',
        tippingPlatformText: 'Processing Fees Covered:',
    },
    ru: {
        tippingCheckoutText:
            'Thank you for covering the processing fees to ensure the maximum of your donation goes to the nonprofit.',
        tippingThankYouText: 'Thank you for covering processing fees:',
        tippingPlatformText: 'Processing Fees Covered:',
    },
    fr: {
        tippingCheckoutText:
            'Thank you for covering the processing fees to ensure the maximum of your donation goes to the nonprofit.',
        tippingThankYouText: 'Thank you for covering processing fees:',
        tippingPlatformText: 'Processing Fees Covered:',
    },
    yi: {
        tippingCheckoutText:
            '<p dir="rtl">תודה על השתתפותך בעלויות הסליקה על מנת שמקסימום מתרומתך יגיע לארגון.</p>',
        tippingThankYouText:
            '<p dir="rtl">תודה על השתתפותך בעלויות הסליקה על סך:</p>',
        tippingPlatformText: 'השתתפות בעלויות סליקה:',
    },
    ar: {
        tippingCheckoutText:
            '<p dir="rtl">תודה על השתתפותך בעלויות הסליקה על מנת שמקסימום מתרומתך יגיע לארגון.</p>',
        tippingThankYouText:
            '<p dir="rtl">תודה על השתתפותך בעלויות הסליקה על סך:</p>',
        tippingPlatformText: 'השתתפות בעלויות סליקה:',
    },
    pt: {
        tippingCheckoutText:
            'Thank you for covering the processing fees to ensure the maximum of your donation goes to the nonprofit.',
        tippingThankYouText: 'Thank you for covering processing fees:',
        tippingPlatformText: 'Processing Fees Covered:',
    },
    de: {
        tippingCheckoutText:
            'Danke, dass Sie die Bearbeitungsgebühren übernehmen! Das Maximum Ihrer Spende geht nun an die Organisation',
        tippingThankYouText: 'Vielen Dank für das Trinkgeld in der Höhe von:',
        tippingPlatformText: 'Trinkgeld:',
    },
    nl: {
        tippingCheckoutText:
            'Bedankt voor het dekken van de verwerkingskosten om ervoor te zorgen dat het maximale van je donatie naar de non-profitorganisatie gaat.',
        tippingThankYouText: 'Bedankt voor het bijvoegen van een fooi van:',
        tippingPlatformText: 'Tip:',
    },
});

module.exports = {
    FINANCIALS_CURRENCY_FISCAL_SPONSOR_TYPES,
    FINANCIALS_CURRENCY_FISCAL_SPONSOR_VALUES,
    FINANCIALS_DEFAULT_TIPPING_TEXTS,
};
