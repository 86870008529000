import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useFormContext } from 'react-hook-form';
import { useIntl } from 'react-intl';
import FormGroup from 'client/components/CampaignPage/components/FormGroup/FormGroup';
import FormInput from 'client/components/CampaignPage/components/FormInput/FormInput';
import { ReactComponent as EmailIcon } from 'client/components/CampaignPage/components/FormInput/EmailIcon.svg';
import { ReactComponent as PhoneIcon } from 'client/components/CampaignPage/components/FormInput/PhoneIcon.svg';
import { ReactComponent as HomeIcon } from 'client/components/CampaignPage/components/FormInput/HomeIcon.svg';
import { selectDefaultFields } from './checkoutSlice';
import FormInputHelperText from '../../components/FormInputHelperText/FormInputHelperText';
import {
    getDefaultFieldLabel,
    isDefaultFieldAllowed,
} from 'client/helpers/checkoutFields';
import { isGiftAidField } from 'client/helpers/giftAid';
import DonationFormPhoneField from './DonationFormPhoneField';
import {
    COUNTRIES_INFO,
    FALLBACK_COUNTRIES,
    PAYMENT_GATEWAYS,
} from 'common/constants';
import config from 'client/config';

function DonationFormDefaultFields({
    getFormClass,
    getFormIcon,
    control,
    paymentGateway,
    defaultPhonePrefix,
}) {
    const { formatMessage } = useIntl();
    const { errors, register, trigger, watch, formState } = useFormContext();
    const defaultFields = useSelector(selectDefaultFields);

    const { giftAid } = watch();

    const fields = useMemo(
        () =>
            Object.entries(defaultFields)
                .map(([name, config]) => {
                    const isGiftAid = Boolean(giftAid) && isGiftAidField(name);
                    return [
                        name,
                        {
                            ...config,
                            isEnabled: config.isEnabled || isGiftAid,
                            isRequired: config.isRequired || isGiftAid,
                        },
                    ];
                })
                .filter(([name, config]) =>
                    isDefaultFieldAllowed({ name, config, paymentGateway }),
                )
                .sort(([, a], [, b]) => a.order - b.order),
        [defaultFields, giftAid, paymentGateway],
    );

    useEffect(() => {
        if (typeof giftAid !== 'undefined' && formState.isSubmitted) {
            trigger();
        }
    }, [giftAid]);

    const getDefaultCountryCode = () => {
        if (paymentGateway === PAYMENT_GATEWAYS.MESHULAM) {
            return 'IL';
        }

        if (defaultPhonePrefix) {
            const defaultCountries = COUNTRIES_INFO.filter(
                ({ phonePrefix }) => phonePrefix === defaultPhonePrefix,
            );

            if (defaultCountries.length === 1) {
                return defaultCountries[0].flagCode;
            }

            if (
                defaultCountries.length > 1 &&
                FALLBACK_COUNTRIES[defaultCountries[0].phonePrefix]
            ) {
                return FALLBACK_COUNTRIES[defaultCountries[0].phonePrefix];
            }
        }

        if (typeof config?.ipCountry === 'string') {
            return config.ipCountry.toUpperCase();
        }

        return null;
    };

    const renderPhoneField = (name, config) => (
        <DonationFormPhoneField
            control={control}
            errors={errors}
            name={name}
            countryCodeClassName={getFormClass('phonePrefix')}
            phoneNumberClassName={getFormClass(name)}
            endIcon={getFormIcon(name)}
            defaultCountry={getDefaultCountryCode()}
            phonePrefixEditable={paymentGateway !== PAYMENT_GATEWAYS.MESHULAM}
            config={config}
        />
    );

    const renderDefaultField = (name, config) => (
        <FormGroup key={name}>
            <FormInput
                type={config.type}
                name={name}
                placeholder={
                    `${config.isRequired ? '* ' : ''}` +
                    getDefaultFieldLabel(formatMessage, {
                        key: name,
                        field: config,
                    })
                }
                ref={register}
                error={errors[name]}
                className={getFormClass(name)}
                endIcon={getFormIcon(name)}
                startIcon={
                    (name === 'email' && <EmailIcon />) ||
                    (name === 'phone' && <PhoneIcon />) ||
                    (name === 'address1' && <HomeIcon />) ||
                    (name === 'address2' && <HomeIcon />)
                }
            />
            <FormInputHelperText error={errors[name]} />
        </FormGroup>
    );

    return fields.map(([name, config]) =>
        name === 'phone'
            ? renderPhoneField(name, config)
            : renderDefaultField(name, config),
    );
}

DonationFormDefaultFields.propTypes = {
    getFormClass: PropTypes.func,
    getFormIcon: PropTypes.func,
    paymentGateway: PropTypes.string,
};

export default DonationFormDefaultFields;
