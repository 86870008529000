import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/styles';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import useIsMobile from 'client/hooks/isMobile';
import {
    selectHeroSlider,
    setupHeroSlider,
    setHeight,
    setImages,
    updateSlide,
    startVideo,
} from './heroSliderSlice';
import HeroBanner from '../HeroBanner/HeroBanner';
import PlayButton from './PlayButton';
import VideoSlide from './VideoSlide';

export const DIMENSIONS = Object.freeze({
    MOBILE: 480 / 540,
    DESKTOP: 360 / 1366,
});

function calculateHeight(windowWidth) {
    return (
        windowWidth *
        (windowWidth < 480 ? DIMENSIONS.MOBILE : DIMENSIONS.DESKTOP)
    );
}

const useStyles = makeStyles(
    () => ({
        root: {
            width: '100%',
            height: ({ height }) => height,
            position: 'relative',
        },

        carousel: {
            width: '100%',
            height: ({ height }) => height,
            '& ul': {
                pointerEvents: 'none',
            },
            '& li': {
                pointerEvents: 'all',
            },
            direction: 'initial',
            '& .carousel .slide iframe': {
                margin: 0,
            },
            '& .carousel .control-dots': {
                zIndex: 998,
            },
            '& .carousel .control-arrow': {
                zIndex: 999,
            },
        },

        videoWrapper: {
            width: '100%',
            height: '100%',
            position: 'absolute',
            top: 0,
            left: 0,
        },

        wrapper: {
            width: '100%',
            height: '100%',
            position: 'relative',
        },

        playButton: {
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            cursor: 'pointer',
        },

        hidden: {
            visibility: 'hidden',
        },
    }),
    { name: 'HeroSlider' },
);

function HeroSlider(props) {
    const dispatch = useDispatch();
    const { height, images, currentSlide, isVideoVisible, isVideoPlaying } =
        useSelector(selectHeroSlider);
    const { width, isMobile, updateWidth } = useIsMobile();
    const classes = useStyles({ height });
    const {
        video,
        slides,
        mobileSlides,
        interval = 0,
        isMobileImagesEnabled,
        isVideoEnabled,
    } = props;
    const sliderInterval = interval > 0 ? interval * 1000 : 6000;
    const hasVideo = isVideoEnabled && video?.url;

    const updateCurrentSlide = index => {
        dispatch(updateSlide(index));
    };

    const changeView = () => {
        dispatch(setImages(getSlides()));
        dispatch(setHeight(calculateHeight(width)));
    };

    const getSlides = () => {
        if (isMobile) return isMobileImagesEnabled ? mobileSlides : '';
        return slides;
    };

    const handleStartVideo = () => {
        dispatch(startVideo());
    };

    const customRenderItem = item => {
        if (isVideoVisible) {
            return (
                <div className={classes.hidden}>
                    <item.type key={item.key} {...item.props} />
                </div>
            );
        }

        return <item.type key={item.key} {...item.props} />;
    };

    const renderSlide = (slide, key) => {
        if (hasVideo) {
            return (
                <div key={key} className={classes.wrapper}>
                    <HeroBanner src={slide} height={height} />
                    <PlayButton
                        className={classes.playButton}
                        onClick={handleStartVideo}
                    />
                </div>
            );
        }

        return <HeroBanner key={key} src={slide} height={height} />;
    };

    useEffect(() => {
        dispatch(setupHeroSlider({ isVideoAutoPlay: !!video?.isAutoPlay }));
        updateWidth();
    }, []);

    useEffect(() => changeView(), [width]);

    if (!images?.length) {
        if (hasVideo) {
            return (
                <VideoSlide
                    blockHeight={height}
                    url={video.url}
                    isVideoOnly={true}
                    isAutoPlay={video?.isAutoPlay}
                    isLoop={video?.isLoop}
                    slideCount={0}
                    isSelected={true}
                />
            );
        }

        return null;
    }

    return (
        <div className={classNames(classes.root, 'hero-slider')}>
            <Carousel
                className={classes.carousel}
                showArrows={width > 160}
                showStatus={false}
                showIndicators={images.length > 1}
                showThumbs={false}
                interval={sliderInterval}
                autoPlay={!isVideoPlaying}
                infiniteLoop
                renderItem={customRenderItem}
                selectedItem={currentSlide}
                onChange={updateCurrentSlide}
            >
                {images.map(renderSlide)}
            </Carousel>
            {hasVideo && (
                <div
                    className={classNames(
                        classes.videoWrapper,
                        isVideoVisible ? null : classes.hidden,
                    )}
                >
                    <VideoSlide
                        blockHeight={height}
                        url={video.url}
                        isAutoPlay={video?.isAutoPlay}
                        isLoop={video?.isLoop}
                        slideCount={images?.length || 0}
                        isSelected={currentSlide === 0}
                    />
                </div>
            )}
        </div>
    );
}

HeroSlider.propTypes = {
    slides: PropTypes.array,
    mobileSlides: PropTypes.array,
    video: PropTypes.object,
    height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    interval: PropTypes.number,
    isMobileImagesEnabled: PropTypes.bool,
    isVideoEnabled: PropTypes.bool,
};

export default HeroSlider;
