const ABANDONED_SETTINGS = Object.freeze({
    en: {
        recovery: {
            enabled: false,
            subject: 'Help {{ORGANIZATION}}',
            delayPeriod: 30,
            delayType: 'minutes',
            emailContent: `<p>This is an automated message to say that we at CauseMatch noticed you didn't complete your recent donation for {{ORGANIZATION}}.</p>
<p>Your gift is very important to {{ORGANIZATION}}!</p>
<p>If you did complete the donation, please ignore this email. If you didn’t complete the donation you can use <a href="{{LINK}}">THIS LINK</a> to go back and complete your transaction.</p>
<p>Please join our campaign today and help {{ORGANIZATION}} continue its important work.</p>
<p>The CauseMatch Team</p>
`,
        },
        notification: {
            enabled: false,
            delayPeriod: 10,
            delayType: 'minutes',
            subject:
                '{{ORGANIZATION}} Campaign - Abandoned Donation Notification',
            emailContent: `<p>Hello!</p> 
<p>This is an automated message to tell you that someone has abandoned the checkout page before completing his or her transaction. Here is the information we were able to pull from the checkout page:</p>

<p>Name of Donor: {{NAME}}</p>
<p>Email: {{EMAIL}}</p>
<p>Donation amount: {{AMOUNT}}</p>
<p>Address line 1: {{ADDRESS_LINE_1}}</p>
<p>Address line 2: {{ADDRESS_LINE_2}}</p>

<p>But don't worry! Donation page abandonment gives you an opportunity to reach out to see if you can recapture whatever magic led this donor to your campaign page in the first place. </p> 
<p>If the user only provided a name, check to see if you know the person’s email address. Don't be afraid to reach out personally and ask if there's any more information s/he needs to complete the transaction.</p>
<p>Don't forget to first check your campaign page donors before reaching out to an abandoned donor, as perhaps s/he has already come back to donate.</p>
<p>-The CauseMatch Campaign Success Team</p>
`,
            isSendToLayerItemManagers: true,
        },
    },
    es: {
        recovery: {
            enabled: false,
            subject: 'Help {{ORGANIZATION}}',
            delayPeriod: 30,
            delayType: 'minutes',
            emailContent: `<p>This is an automated message to say that we at CauseMatch noticed you didn't complete your recent donation for {{ORGANIZATION}}.</p>
<p>Your gift is very important to {{ORGANIZATION}}!</p>
<p>If you did complete the donation, please ignore this email. If you didn’t complete the donation you can use <a href="{{LINK}}">THIS LINK</a> to go back and complete your transaction.</p>
<p>Please join our campaign today and help {{ORGANIZATION}} continue its important work.</p>
<p>The CauseMatch Team</p>
            `,
        },
        notification: {
            enabled: false,
            delayPeriod: 10,
            delayType: 'minutes',
            subject:
                '{{ORGANIZATION}} Campaign - Abandoned Donation Notification',
            emailContent: `<p>Hello!</p> 
<p>This is an automated message to tell you that someone has abandoned the checkout page before completing his or her transaction. Here is the information we were able to pull from the checkout page:</p>

<p>Name of Donor: {{NAME}}</p>
<p>Email: {{EMAIL}}</p>
<p>Donation amount: {{AMOUNT}}</p>
<p>Address line 1: {{ADDRESS_LINE_1}}</p>
<p>Address line 2: {{ADDRESS_LINE_2}}</p>

<p>But don't worry! Donation page abandonment gives you an opportunity to reach out to see if you can recapture whatever magic led this donor to your campaign page in the first place. </p> 
<p>If the user only provided a name, check to see if you know the person’s email address. Don't be afraid to reach out personally and ask if there's any more information s/he needs to complete the transaction.</p>
<p>Don't forget to first check your campaign page donors before reaching out to an abandoned donor, as perhaps s/he has already come back to donate.</p>
<p>-The CauseMatch Campaign Success Team</p>
`,
            isSendToLayerItemManagers: true,
        },
    },
    fr: {
        recovery: {
            enabled: false,
            subject: 'Help {{ORGANIZATION}}',
            delayPeriod: 30,
            delayType: 'minutes',
            emailContent: `<p>This is an automated message to say that we at CauseMatch noticed you didn't complete your recent donation for {{ORGANIZATION}}.</p>
<p>Your gift is very important to {{ORGANIZATION}}!</p>
<p>If you did complete the donation, please ignore this email. If you didn’t complete the donation you can use <a href="{{LINK}}">THIS LINK</a> to go back and complete your transaction.</p>
<p>Please join our campaign today and help {{ORGANIZATION}} continue its important work.</p>
<p>The CauseMatch Team</p>
            `,
        },
        notification: {
            enabled: false,
            delayPeriod: 10,
            delayType: 'minutes',
            subject:
                '{{ORGANIZATION}} Campaign - Abandoned Donation Notification',
            emailContent: `<p>Hello!</p> 
<p>This is an automated message to tell you that someone has abandoned the checkout page before completing his or her transaction. Here is the information we were able to pull from the checkout page:</p>

<p>Name of Donor: {{NAME}}</p>
<p>Email: {{EMAIL}}</p>
<p>Donation amount: {{AMOUNT}}</p>
<p>Address line 1: {{ADDRESS_LINE_1}}</p>
<p>Address line 2: {{ADDRESS_LINE_2}}</p>

<p>But don't worry! Donation page abandonment gives you an opportunity to reach out to see if you can recapture whatever magic led this donor to your campaign page in the first place. </p> 
<p>If the user only provided a name, check to see if you know the person’s email address. Don't be afraid to reach out personally and ask if there's any more information s/he needs to complete the transaction.</p>
<p>Don't forget to first check your campaign page donors before reaching out to an abandoned donor, as perhaps s/he has already come back to donate.</p>
<p>-The CauseMatch Campaign Success Team</p>
`,
            isSendToLayerItemManagers: true,
        },
    },
    ru: {
        recovery: {
            enabled: false,
            subject: 'Help {{ORGANIZATION}}',
            delayPeriod: 30,
            delayType: 'minutes',
            emailContent: `<p>This is an automated message to say that we at CauseMatch noticed you didn't complete your recent donation for {{ORGANIZATION}}.</p>
<p>Your gift is very important to {{ORGANIZATION}}!</p>
<p>If you did complete the donation, please ignore this email. If you didn’t complete the donation you can use <a href="{{LINK}}">THIS LINK</a> to go back and complete your transaction.</p>
<p>Please join our campaign today and help {{ORGANIZATION}} continue its important work.</p>
<p>The CauseMatch Team</p>
            `,
        },
        notification: {
            enabled: false,
            delayPeriod: 10,
            delayType: 'minutes',
            subject:
                '{{ORGANIZATION}} Campaign - Abandoned Donation Notification',
            emailContent: `<p>Hello!</p> 
<p>This is an automated message to tell you that someone has abandoned the checkout page before completing his or her transaction. Here is the information we were able to pull from the checkout page:</p>

<p>Name of Donor: {{NAME}}</p>
<p>Email: {{EMAIL}}</p>
<p>Donation amount: {{AMOUNT}}</p>
<p>Address line 1: {{ADDRESS_LINE_1}}</p>
<p>Address line 2: {{ADDRESS_LINE_2}}</p>

<p>But don't worry! Donation page abandonment gives you an opportunity to reach out to see if you can recapture whatever magic led this donor to your campaign page in the first place. </p> 
<p>If the user only provided a name, check to see if you know the person’s email address. Don't be afraid to reach out personally and ask if there's any more information s/he needs to complete the transaction.</p>
<p>Don't forget to first check your campaign page donors before reaching out to an abandoned donor, as perhaps s/he has already come back to donate.</p>
<p>-The CauseMatch Campaign Success Team</p>
`,
            isSendToLayerItemManagers: true,
        },
    },
    ar: {
        recovery: {
            enabled: false,
            subject: 'Help {{ORGANIZATION}}',
            delayPeriod: 30,
            delayType: 'minutes',
            emailContent: `<p>This is an automated message to say that we at CauseMatch noticed you didn't complete your recent donation for {{ORGANIZATION}}.</p>
<p>Your gift is very important to {{ORGANIZATION}}!</p>
<p>If you did complete the donation, please ignore this email. If you didn’t complete the donation you can use <a href="{{LINK}}">THIS LINK</a> to go back and complete your transaction.</p>
<p>Please join our campaign today and help {{ORGANIZATION}} continue its important work.</p>
<p>The CauseMatch Team</p>
            `,
        },
        notification: {
            enabled: false,
            delayPeriod: 10,
            delayType: 'minutes',
            subject:
                '{{ORGANIZATION}} Campaign - Abandoned Donation Notification',
            emailContent: `<p>Hello!</p> 
<p>This is an automated message to tell you that someone has abandoned the checkout page before completing his or her transaction. Here is the information we were able to pull from the checkout page:</p>

<p>Name of Donor: {{NAME}}</p>
<p>Email: {{EMAIL}}</p>
<p>Donation amount: {{AMOUNT}}</p>
<p>Address line 1: {{ADDRESS_LINE_1}}</p>
<p>Address line 2: {{ADDRESS_LINE_2}}</p>

<p>But don't worry! Donation page abandonment gives you an opportunity to reach out to see if you can recapture whatever magic led this donor to your campaign page in the first place. </p> 
<p>If the user only provided a name, check to see if you know the person’s email address. Don't be afraid to reach out personally and ask if there's any more information s/he needs to complete the transaction.</p>
<p>Don't forget to first check your campaign page donors before reaching out to an abandoned donor, as perhaps s/he has already come back to donate.</p>
<p>-The CauseMatch Campaign Success Team</p>
`,
            isSendToLayerItemManagers: true,
        },
    },
    he: {
        recovery: {
            enabled: false,
            subject: 'עזרו ל-{{ORGANIZATION}} להגיע ליעד!',
            delayPeriod: 30,
            delayType: 'minutes',
            emailContent: `<p dir="rtl">שלום רב, </p>
<p dir="rtl">זוהי הודעה אוטומטית לומר לך שאנחנו בקוזמאצ' שמנו לב שלא השלמת את תהליך התרומה ל{{ORGANIZATION}}.</p>
<p dir="rtl">במידה וחזרת והשלמת את התרומה ההודעה הזאת לא רלוונטית עבורך!! במידה ולא סיימת אתה יכול להשתמש <a href="{{LINK}}">בקישור הזה</a> כדי לחזור ולהשלים את התרומה.</p>
<p dir="rtl">אנא קח חלק בקמפיין שלנו עוד היום ועזור ל-{{ORGANIZATION}} להמשיך לעשות טוב.</p>
<p dir="rtl">תודה רבה!</p>
<p dir="rtl">צוות קוזמאצ'</p>
`,
        },
        notification: {
            enabled: false,
            delayPeriod: 10,
            delayType: 'minutes',
            subject: '{{ORGANIZATION}} - הודעה על תרומה שננטשה',
            emailContent: `
            <p dir="rtl">שלום רב!</p>
<p dir="rtl">זוהי הודעה אוטומטית שמישהו עזב את דף התשלום מבלי להשלים את התרומה.</p>
<p dir="rtl">הנה המידע שהצלחנו להוציא מדף התשלום:</p>
<p dir="rtl">שם התורם/ת: {{NAME}}</p>
<p dir="rtl">מייל: {{EMAIL}}</p>
<p dir="rtl">סכום התרומה: {{AMOUNT}}</p>
<p dir="rtl">כתובת שורה 1: {{ADDRESS_LINE_1}}</p>
<p dir="rtl">כתובת שורה 2: {{ADDRESS_LINE_2}}</p>
<p dir="rtl">אבל אל דאגה! תרומות ננטשות נותנות הזדמנות ליצירת קשר עם תורמים ולהדליק מחדש את האש שהביאה אותו או אותה לדף הקמפיין מלכתחילה.</p>
<p dir="rtl">אם התורם או התורמת סיפקו שם בלבד בררו אם יש לכם את כתובת המייל שלו/ה. אל תהססו ליצור קשר ישירות ולברר האם הוא/היא זקוק/ה למידע נוסף כדי להשלים את התרומה.</p>
<p dir="rtl">אם יש לכם שאלות, אל תהססו ליצור קשר עם מנהל/ת הקמפיין שלכם!</p>
<p dir="rtl">בברכה, צוות קוזמאצ׳</p>
`,
            isSendToLayerItemManagers: true,
        },
    },
    yi: {
        recovery: {
            enabled: false,
            subject: 'עזרו ל-{{ORGANIZATION}} להגיע ליעד!',
            delayPeriod: 30,
            delayType: 'minutes',
            emailContent: `<p dir="rtl">שלום רב, </p>
<p dir="rtl">זוהי הודעה אוטומטית לומר לך שאנחנו בקוזמאצ' שמנו לב שלא השלמת את תהליך התרומה ל{{ORGANIZATION}}.</p>
<p dir="rtl">במידה וחזרת והשלמת את התרומה ההודעה הזאת לא רלוונטית עבורך!! במידה ולא סיימת אתה יכול להשתמש <a href="{{LINK}}">בקישור הזה</a> כדי לחזור ולהשלים את התרומה.</p>
<p dir="rtl">אנא קח חלק בקמפיין שלנו עוד היום ועזור ל{{ORGANIZATION}} להמשיך לעשות טוב.</p>
<p dir="rtl">תודה רבה!</p>
<p dir="rtl">צוות קוזמאצ'</p>
`,
        },
        notification: {
            enabled: false,
            delayPeriod: 10,
            delayType: 'minutes',
            subject: '{{ORGANIZATION}} - הודעה על תרומה שננטשה',
            emailContent: `
            <p dir="rtl">שלום רב!</p>
<p dir="rtl">זוהי הודעה אוטומטית שמישהו עזב את דף התשלום מבלי להשלים את התרומה.</p>
<p dir="rtl">הנה המידע שהצלחנו להוציא מדף התשלום:</p>
<p dir="rtl">שם התורם/ת: {{NAME}}</p>
<p dir="rtl">מייל: {{EMAIL}}</p>
<p dir="rtl">סכום התרומה: {{AMOUNT}}</p>
<p dir="rtl">כתובת שורה 1: {{ADDRESS_LINE_1}}</p>
<p dir="rtl">כתובת שורה 2: {{ADDRESS_LINE_2}}</p>
<p dir="rtl">אבל אל דאגה! תרומות ננטשות נותנות הזדמנות ליצירת קשר עם תורמים ולהדליק מחדש את האש </p>שהביאה אותו או אותה לדף הקמפיין מלכתחילה.
<p dir="rtl">אם התורם או התורמת סיפקו שם בלבד בררו אם יש לכם את כתובת המייל שלו/ה. אל תהססו </p>ליצור קשר ישירות ולברר האם הוא/היא זקוק/ה למידע נוסף כדי להשלים את התרומה.
<p dir="rtl">אם יש לכם שאלות, אל תהססו ליצור קשר עם מנהל/ת הקמפיין שלכם!</p>
<p dir="rtl">בברכה, צוות קוזמאצ׳</p>
`,
            isSendToLayerItemManagers: true,
        },
    },
});

const CAMPAIGN_CATEGORIES = Object.freeze([
    'institutions',
    'medical',
    'commercial',
    'organization',
    'other',
]);

const CAMPAIGN_CONFETTI_TRIGGER_TYPES = Object.freeze({
    GOAL_REACHED: 'goalReached',
    CLOCK_RUNS_OUT: 'clockRunsOut',
    BONUS_1_REACHED: 'bonus1Reached',
    BONUS_2_REACHED: 'bonus2Reached',
    ALWAYS: 'always',
});

const CAMPAIGN_DEFAULT_DONATION_TEXT = Object.freeze({
    en: `
    <p>
        For questions regarding donations or the campaign please contact [(Optional) Name of Contact]: [Contact Email] or [Contact Phone]
    </p>
    <p>
        Donations in [Currency] are tax-deductible in [country] through [official organization name, charity number/EIN]
    </p>
`,
    he: `
    <p dir="rtl">
        לשאלות אודות הקמפיין או תרומות, אנא צרו קשר עם [שם]: [אימייל] או [נייד]
    </p>
    <p dir="rtl">
        קבלות תרומה [אם רלוונטי הוסף: מוכרות לצורכי מס בישראל] ישלחו למייל האישי בעת סיום התרומה.
    </p>
`,
});

const DEFAULT_PFL_MESSAGE = Object.freeze({
    SUBJECT: {
        en: 'Your Personal Fundraising Link for {{ORGANIZATION}}’s campaign!',
    },
    CONTENT: {
        en: `<p>Dear {{NAME}},</p>
    <p>
        Thank you for your display of generosity to {{ORGANIZATION}}’s
        campaign!
    </p>
    <p>
        Now, you can take your giving to the next level to make an even
        greater impact on the causes nearest to your heart.
    </p>
    <p>
        We've generated a unique Personal Fundraising Link just for you:
        <a href="{{DONOR_PFL}}">{{DONOR_PFL}}</a>
    </p>
    <p>
        By sharing this link with your friends, family, and colleagues, you
        can inspire others to join your mission and contribute towards the
        cause, multiplying the impact of your generosity.
    </p>
    <p>
        With your Personal Fundraising Link, fundraising becomes personal,
        interactive, and rewarding. Here's how it works:
    </p>
    <ol>
        <li
            >Share Your Passion: By sharing your link, you have the
            opportunity to communicate why you love this cause. Your
            personal touch will inspire others to take action and make a
            difference.
        </li>
        <li
            >Multiply Your Impact: Every donation made to {{ORGANIZATION}}’s
            campaign through your Personal Fundraising Link will be
            associated with you. You'll be able to track the progress of the
            campaign, see the collective impact you've made, and celebrate
            the milestones together.
        </li>
        <li
            >Engage Your Network: Encourage your network to share your link
            with their contacts, amplifying the reach of your campaign. You
            can begin a ripple of generosity and change.
        </li>
    </ol>
    <p>
        Become a catalyst for kindness by sharing your Personal Fundraising
        Link and inspiring others to join the movement of positive change.
    </p>
    <p>Once again, your personal fundraising link is: <a href="{{DONOR_PFL}}">{{DONOR_PFL}}</a></p>
    <p>Looking forward to seeing how high you can fly!</p>
    <p>-The CauseMatch Team</p>`,
    },
});

const DEFAULT_MMG_MESSAGE = Object.freeze({
    SUBJECT: {
        en: 'The gift that keeps on giving!',
        he: 'תרומתך היא המתנה שממשיכה לתת!',
    },
    CONTENT: {
        en: `<p>Yours is the gift that keeps on giving!</p>
    <p>
        You donated {{AMOUNT}} to {{ORGANIZATION}}. {{MATCHED_DONOR_NAME}} has decided to match your gift with an additional donation of {{AMOUNT}}!
    </p>
    <p>
        While we can't give you a real trophy, we can put a trophy icon next to your name and tell everyone that your donation has inspired someone else to give. Now, if you go to the <b>CAMPAIGN PAGE</b>, you'll see your name, your special trophy icon, and a number indicating how many people have matched your gift.
    </p>
    <p>
        Thank you for stepping up and becoming a leader of this cause. The world needs more people like you who are willing to give of their time and resources to make a positive impact.
    </p>
    <p>
        Warmly,<br>
        The CauseMatch Team
    </p>`,
        he: `<p dir="rtl">מזל טוב!</p>
    <p dir="rtl">
        {{MATCHED_DONOR_NAME}} החליט/ה להצטרף לתרומתך על סך {{AMOUNT}} לארגון {{ORGANIZATION}}!
    </p>
    <p dir="rtl">
    התרומה שנוספה לתרומתך משתקפת באמצעות גביע קטן שמופיע עכשיו ליד השם שלך על דף הקמפיין <b>[לקשר לדף הקמפיין]</b>, חפש את שמך ברשימת התורמים על דף הקמפיין כדי לראות אותו. 
    </p>
    <p dir="rtl">
        המספר שעל הגביע יתעדכן עם כל תרומה שתצטרף לתרומתך.
    </p>
    <p dir="rtl">
        תוכל/י לשתף את דף הקמפיין עם משפחה וחברים שיחפשו את שמך ברשימת התורמים ויצטרפו לתרומתך. 
    </p>
    <p dir="rtl">
    בברכה,<br />
    צוות קוזמאצ׳
    </p>`,
    },
});

const CAMPAIGN_THEME_PALETTE = Object.freeze({
    primary: {
        main: '#0040DD', // blue
    },
    secondary: {
        main: '#FFAD00', // orange
        contrastText: '#4a4a4a', // dark grey
    },
    error: {
        main: '#DF192D', // red
        contrastText: '#fff',
    },
    success: {
        main: '#52BF55', // green
        contrastText: '#4a4a4a',
    },
    info: {
        main: '#E6E6E6', // light gray
        contrastText: '#4a4a4a',
    },
    background: {
        default: '#fff',
        paper: '#fff',
    },
    text: {
        primary: '#4a4a4a',
    },
});

const EDITABLE_CAMPAIGN_COLOR_TOKENS = ['secondary'];

const VALID_MATCH_RATIOS = [1, 1.5, 2, 3, 4];

module.exports = {
    ABANDONED_SETTINGS,
    CAMPAIGN_CATEGORIES,
    CAMPAIGN_CONFETTI_TRIGGER_TYPES,
    CAMPAIGN_DEFAULT_DONATION_TEXT,
    DEFAULT_PFL_MESSAGE,
    DEFAULT_MMG_MESSAGE,
    CAMPAIGN_THEME_PALETTE,
    EDITABLE_CAMPAIGN_COLOR_TOKENS,
    VALID_MATCH_RATIOS,
};
