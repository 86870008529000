import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';

const useStyles = makeStyles(
    ({ palette }) => ({
        root: {
            fontSize: '1rem',
            lineHeight: '19px',
            color: palette.grey[600],
            padding: 0,
            margin: 0,

            '& p': {
                margin: 0,
            },
        },

        htmlContent: {
            width: '100%',
            margin: 0,
            padding: 0,
            border: 'none',
            display: 'inline-block',

            '& div.embed-width-20': {
                width: '20%',
                margin: '1em auto',
            },
            '& div.embed-width-40': {
                width: '40%',
                margin: '1em auto',
            },
            '& div.embed-width-60': {
                width: '60%',
                margin: '1em auto',
            },
            '& div.embed-width-80': {
                width: '80%',
                margin: '1em auto',
            },
            '& div.embed-width-100': {
                width: '100%',
                margin: '1em auto',
            },
            '& div.embed-float-left': {
                margin: '0 1em 0.5em',
                float: 'left',
            },
            '& div.embed-float-right': {
                margin: '0 1em 0.5em',
                float: 'right',
            },
            '& div.embed-float-none': {
                float: 'none',
            },
            '@media (max-width: 600px)': {
                '& div.embed-width-20, & div.embed-width-40, & div.embed-width-60, & div.embed-width-80':
                    {
                        width: '100%',
                        margin: '1em auto',
                    },
            },
        },
    }),
    { name: 'Text' },
);

function Text(props) {
    const {
        children,
        component: Component = 'div',
        className,
        html = false,
        ...rest
    } = props;
    const classes = useStyles();

    return (
        <Component className={classNames(classes.root, className)} {...rest}>
            {html ? (
                <div
                    className={classes.htmlContent}
                    dangerouslySetInnerHTML={{ __html: children }}
                ></div>
            ) : (
                children
            )}
        </Component>
    );
}

Text.propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
    component: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    html: PropTypes.bool,
};

export default Text;
