const DONATE_PAGE_DEFAULT_RECEIPT_BODY_VALUE = `
<h2>Thank you!</h2>
<p>Dear {{NAME}},</p>
<p>Thank you for your generous donation of {{AMOUNT}} to {{ORGANIZATION}} on {{DATE}}. &nbsp;</p>
<p>Receipt ID {{RECEIPT_ID}}</p>
<figure class="image">
<img src="https://s3-us-west-2.amazonaws.com/causematch-static-content/wp-content/uploads/2020/03/03100007/facebook-share-button.png" alt="facebook-share-button"></figure>
<p><strong>Thank you for helping make the project a reality!!!&nbsp;</strong></p>
<p><i>{{ORGANIZATION}}</i></p>
`;

const DONATE_PAGE_DEFAULT_RECEIPT_BODY_VALUE_GBP = `
<h2>Thank you!</h2>
<p>Dear {{NAME}},</p>
<p>Thank you for your generous donation of {{AMOUNT}} to {{ORGANIZATION}} on {{DATE}}. &nbsp;</p>
<p>We have registered that you {{GIFTAID}} give your consent for GiftAid to be claimed on this donation.</p>
<p>Receipt ID {{RECEIPT_ID}}</p>
<figure class="image">
<img src="https://s3-us-west-2.amazonaws.com/causematch-static-content/wp-content/uploads/2020/03/03100007/facebook-share-button.png" alt="facebook-share-button"></figure>
<p><strong>Thank you for helping make the project a reality!!!&nbsp;</strong></p>
<p><i>{{ORGANIZATION}}</i></p>
`;

const DONATE_PAGE_DEFAULT_RECEIPT_SUBJECT_VALUE =
    'Thank you for your donation!';

const DEFAULT_RECEIPT_PDF_FREE_TEXT = `Thank you for supporting our mission!
Because of you, we are able to effect real change! Please amplify your impact by sharing our campaign with friends and family.
{{ORGANIZATION}}`;
const DEFAULT_RECEIPT_LANGUAGE = 'en';
const RECEIPT_PDF_ATTACHMENT_PREFIX = 'cm_receipt_';

const DEFAULT_RECEIPT_SUBJECT_VALUE = 'Thank you for your donation!';
const DEFAULT_RECEIPT_BODY_VALUE = `
<p>Thank you!</p>
<hr>
<p>Dear {{NAME}},</p>
<p>Thank you for your generous tax-deductible donation of {{AMOUNT}} to {{ORGANIZATION}} on {{DATE}}.</p>
<p>{{LAYER_ITEM_AMOUNTS_LIST}}</p>
<p><b>xxxxx-Organization Name-xxxxxx</b> is a U.S. tax-exempt 501(c)(3) organization (Tax ID # xxxxxxx).</p>
<p><i>Because of you, we are able to effect real change! Please amplify your impact by <strong>sharing the campaign with friends and family:&nbsp;</strong></i></p>
<p>Your personal fundraising link is: <a href="{{DONOR_PFL}}" target="_blank">{{DONOR_PFL}}</a></p>
<p>Every donation made through your Personal Fundraising Link will be associated with you. You'll be able to track the progress of your campaign, see the collective impact you've made, and celebrate the milestones together.</p>
<a href="https://www.facebook.com/sharer/sharer.php?u=https://causematch.com/xxxxx" target="_blank">
    <img src="https://s3-us-east-2.amazonaws.com/cm2-nodejs-files/files/807373f0-d080-424c-8902-0ed1badbba1c.png" alt="facebook" />
</a>
<a href="https://api.whatsapp.com/send?phone=&text=https://causematch.com/xxxxx" target="_blank">
    <img src="https://s3-us-east-2.amazonaws.com/cm2-nodejs-files/files/d6447092-a2cd-496c-8ac9-fdca514a317e.png" alt="whatsapp" />
</a>
<p>Thank you for supporting our mission,<br>
{{ORGANIZATION}}</p>
<p style="text-align: center;"><i>*US Tax-Deductible Receipt Disclaimer: In accordance with the IRS tax-codes, <b>xxxxx-Organization Name-xxxxxx</b> hereby affirms that no goods or services of significant value were received in exchange for this gift.</i></p>
<hr>
<p>Receipt ID {{RECEIPT_ID}}</p>
<p><i>We want to hear from you about the great (and not-so-great) things about donating through the CauseMatch campaign pages. Please help us by filling out <a href="https://survey.qwary.com/form/S_wSzSPnasH9Wc_FT15X0J1BuEcPl5gIaOdIfhcLdeQ=" target="_blank">THIS</a> survey!</i>
</p>
`;

const DEFAULT_RECEIPT_OFFLINE_SUBJECT_VALUE = 'Thank you for your donation!';
const DEFAULT_RECEIPT_OFFLINE_BODY_VALUE = `
<p>Thank you!</p>
<hr>
<p>Dear {{NAME}},</p>
<p>Thank you for your generous donation of {{AMOUNT}} to {{ORGANIZATION}} on {{DATE}}.</p>
<p>{{LAYER_ITEM_AMOUNTS_LIST}}</p>
<p><i>Because of you, we are able to effect real change! Please amplify your impact by <strong>sharing the campaign with friends and family:&nbsp;</strong></i></p>
<p>Your personal fundraising link is: <a href="{{DONOR_PFL}}" target="_blank">{{DONOR_PFL}}</a></p>
<p>Every donation made through your Personal Fundraising Link will be associated with you. You'll be able to track the progress of your campaign, see the collective impact you've made, and celebrate the milestones together.</p>
<a href="https://www.facebook.com/sharer/sharer.php?u=https://causematch.com/xxxxx" target="_blank">
    <img src="https://s3-us-east-2.amazonaws.com/cm2-nodejs-files/files/807373f0-d080-424c-8902-0ed1badbba1c.png" alt="facebook" />
</a>
<a href="https://api.whatsapp.com/send?phone=&text=https://causematch.com/xxxxx" target="_blank">
    <img src="https://s3-us-east-2.amazonaws.com/cm2-nodejs-files/files/d6447092-a2cd-496c-8ac9-fdca514a317e.png" alt="whatsapp" />
</a>
<p>Thank you for supporting our mission,<br>
{{ORGANIZATION}}</p>
<hr>
<p>Donation ID {{RECEIPT_ID}}</p>
<p><i>We want to hear from you about the great (and not-so-great) things about donating through the CauseMatch campaign pages. Please help us by filling out <a href="https://survey.qwary.com/form/S_wSzSPnasH9Wc_FT15X0J1BuEcPl5gIaOdIfhcLdeQ=" target="_blank">THIS</a> survey!</i>
</p>
`;

const DEFAULT_NOTIFICATION_SUBJECT = {
    en: 'You have a new donation!',
    he: 'התקבלה תרומה חדשה!',
};
const DEFAULT_NOTIFICATION_BODY = {
    en: `<p><b>You have a new donation!</b></p>
<p>This donation is {{IS_ANONYMOUS}}</p>
<p>
    Donor Name: {{NAME}}<br />
    Email: {{EMAIL}}<br />
    Donation Amount: {{AMOUNT}}<br />
    Donation Type: {{DON_TYPE}}<br />
    Date: {{DATE}}<br />
    Comment: {{COMMENT}}<br />
</p>
<p>
    This donation was divided between the below ambassador page:<br />
    {{LAYER_ITEM_AMOUNTS_LIST}}
</p>
`,
    he: `<p dir="rtl"><b>התקבלה תרומה חדשה!</b></p>
<p dir="rtl">
    שם התורם: {{NAME}}<br />
    אימייל: {{EMAIL}}<br />
    סכום התרומה: {{AMOUNT}}<br />
    סוג התרומה: {{DON_TYPE}}<br />
    תאריך: {{DATE}}<br />
    הקדשה: {{COMMENT}}<br />
</p>
`,
};

module.exports = {
    DONATE_PAGE_DEFAULT_RECEIPT_BODY_VALUE,
    DONATE_PAGE_DEFAULT_RECEIPT_SUBJECT_VALUE,
    DONATE_PAGE_DEFAULT_RECEIPT_BODY_VALUE_GBP,
    DEFAULT_RECEIPT_LANGUAGE,
    DEFAULT_RECEIPT_PDF_FREE_TEXT,
    RECEIPT_PDF_ATTACHMENT_PREFIX,
    DEFAULT_RECEIPT_SUBJECT_VALUE,
    DEFAULT_RECEIPT_BODY_VALUE,
    DEFAULT_RECEIPT_OFFLINE_SUBJECT_VALUE,
    DEFAULT_RECEIPT_OFFLINE_BODY_VALUE,
    DEFAULT_NOTIFICATION_SUBJECT,
    DEFAULT_NOTIFICATION_BODY,
};
