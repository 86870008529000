const campaign = require('./campaign');
const campaignEmailTemplate = require('./campaignEmailTemplate');
const countries = require('./countries');
const currencies = require('./currencies');
const donatePage = require('./donatePage');
const donation = require('./donation');
const financials = require('./financials');
const gateways = require('./gateways');
const homeSite = require('./homeSite');
const inputFields = require('./inputFields');
const languages = require('./languages');
const layerItem = require('./layerItem');
const liveEventTicker = require('./liveEventTicker');
const mergeTags = require('./mergeTags');
const messageAnalytics = require('./messageAnalytics');
const messaging = require('./messaging');
const notification = require('./notification');
const outgoingWebhook = require('./outgoingWebhook');
const pageBuilder = require('./pageBuilder');
const passwordStrengthErrors = require('./passwordStrengthErrors');
const payments = require('./payments');
const rallyEvent = require('./rallyEvent');
const receipt = require('./receipt');
const signupPage = require('./signupPage');
const templates = require('./templates');
const token = require('./token');
const trackingParams = require('./trackingParams');
const user = require('./user');

const SORT_BY = Object.freeze({
    CREATED_AT_DESC: 'createdAt:desc',
    DONATION_NAME_ASC: 'isAnonymous:asc,name:asc',
    DONATION_NAME_DESC: 'isAnonymous:desc,name:desc',
    DONATION_AMOUNT_DESC: '$baseCurrencyAmount$:desc',
    DONATION_AMOUNT_ASC: '$baseCurrencyAmount$:asc',
});

const SLUG_RESERVED_KEYWORDS = Object.freeze([
    'd',
    'login',
    'dashboard',
    'api',
    'auth',
    'signup',
    'how-it-works',
    'new-campaign',
    'customers',
    'faq',
    'contact',
    'our-rules',
    'live-campaigns',
    'privacy',
    'terms',
    'search',
    'list',
    'test',
    'event',
    'donation',
    'team',
    'community',
    'country',
    'crowdfunding',
    'nonprofits',
    'clients',
    'campaign',
    'get-started',
    'causematch-crowdfunding',
    'platform',
    'home',
    'checkout',
    'donate',
    'search',
    'live-ticker',
    'projects',
    ...homeSite.HOME_RESERVED_SLUGS,
]);

const DATE_DISPLAY_FORMAT = 'll';
const TIME_DISPLAY_FORMAT = 'hh:mm a';
const DATE_TIME_DISPLAY_FORMAT = `${DATE_DISPLAY_FORMAT}, ${TIME_DISPLAY_FORMAT}`;

const FEATURES = Object.freeze({ ACCOUNT: 'account' });

// age limit for all cookies is 400 days
// https://developer.chrome.com/blog/cookie-max-age-expires/
const MAX_COOKIE_AGE = 400 * 24 * 60 * 60 * 1000; // milliseconds

module.exports = {
    ...campaign,
    ...campaignEmailTemplate,
    ...countries,
    ...currencies,
    ...donatePage,
    ...donation,
    ...financials,
    ...gateways,
    ...homeSite,
    ...inputFields,
    ...languages,
    ...layerItem,
    ...liveEventTicker,
    ...mergeTags,
    ...messageAnalytics,
    ...messaging,
    ...notification,
    ...outgoingWebhook,
    ...pageBuilder,
    ...passwordStrengthErrors,
    ...payments,
    ...rallyEvent,
    ...receipt,
    ...signupPage,
    ...templates,
    ...token,
    ...trackingParams,
    ...user,
    DATE_DISPLAY_FORMAT,
    DATE_TIME_DISPLAY_FORMAT,
    FEATURES,
    MAX_COOKIE_AGE,
    SLUG_RESERVED_KEYWORDS,
    SORT_BY,
    TIME_DISPLAY_FORMAT,
};
